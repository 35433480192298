import React, { useState } from "react";
import { LiveChatLoaderProvider } from "react-live-chat-loader";

import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <LiveChatLoaderProvider
      provider={"intercom"}
      providerKey={process.env.GATSBY_INTERCOM_APP_ID || ""}
    >
      {/* removing chat provider */}
      {/* <Intercom color={"#5B2EBC"} /> */}
      <div>
        <Navbar />

        {children}
        <Footer />
      </div>
    </LiveChatLoaderProvider>
  );
}
