import React from "react";

type PROPS_TYPE = {
  text: string;
  style?: React.CSSProperties;
  textStyle?: string;
  className?: string;
  id?: any;
  component?: "h1" | "h2" | "h3";
};
export default function Text(props: PROPS_TYPE) {
  let styleToImplement;
  if (!props.style && !props.textStyle && !props.className) {
    styleToImplement = {
      fontFamily: "Mazzard, sans-serif",
    };
  } else if (!props.textStyle) styleToImplement = props.style;
  const Component = props.component || "span";
  return (
    <Component
      id={props.id}
      className={"text-[#323B4B] " + props.className + " " + props.textStyle}
      style={styleToImplement}
    >
      {props.text}
    </Component>
  );
}
