import * as React from "react";
import Layout from "../organisms/Layout";
import { useState } from "react";
import Wrapper from "../atoms/Wrapper";
import Container from "../atoms/Container";
import Text from "../atoms/Text";
import caricature from "../images/404.svg";
import Button from "../atoms/Button";
import { Link } from "gatsby";
// markup
const NotFoundPage = () => {
  const [showWaitListForm, setShowWaitListForm] = useState(false); //to keep track of visibility of modal

  function displayWaitListForm() {
    //display the wait-list modal/form by setting showWaitListFrom to true
    setShowWaitListForm(true);
  }

  function hideDisplayWaitListForm() {
    //hide the wait-list modal/form by setting showWaitListFrom to false
    setShowWaitListForm(false);
  }
  return (
    <Layout
      footerClassName={"bg-[#F8F9FA]"}
      displayWaitListForm={displayWaitListForm}
      showWaitListForm={showWaitListForm}
      hideDisplayWaitListForm={hideDisplayWaitListForm}
    >
      <Wrapper className={"bg-[#F8F9FA]"}>
        <Container className={"flex flex-col items-center pt-8"}>
          <div className={"mb-3"}>
            <Text text={"Oops! No results found"} textStyle={"h1"} />
          </div>
          <div className={"mb-16"}>
            <Text
              text={
                "We couldn’t find what you searched for. Try searching again."
              }
              textStyle={"s1"}
            />
          </div>
          <div className={"mb-20"}>
            <img src={caricature} alt={"not found"} />
          </div>
          <div>
            <Link to={"/"}>
              <Button
                width={465}
                height={56}
                variant={"outline"}
                id={"back-to-home"}
              >
                Back to Homepage
              </Button>
            </Link>
          </div>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
