import React from "react";

type PROPS_STYLES = {
  className?: string;
  id?: string;
};

export default function Wrapper({
  children,
  className,
  id,
}: React.PropsWithChildren<PROPS_STYLES>) {
  return (
    <div id={id} className={className + " "}>
      {children}
    </div>
  );
}
